import React, { useState, useEffect } from 'react';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//import BlogService from '../services/BlogService';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Card, Row, Col, Container } from "react-bootstrap";
import FormDialog from "../../admin/components/FormDialog";
import StudentEnrollment from "./StudentEnrollment";
import FormitLogger  from "../../common/FormitLogger";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  Stepper,
  Step,
  StepLabel,
  Button, Typography, TextField,
  CircularProgress
} from '@material-ui/core';
import InputField from '../../components/formfields/InputField';
import SelectField from '../../components/formfields/SelectField';
import StudentService from '../../services/StudentService';


const courseGroups = [
  {
    value: 'JAVA',
    label: 'Java'
  },
  {
    value: '.NET',
    label: '.Net'
  },
  {
    value: 'DB',
    label: 'Database'
  }
];


const offerTypes = [
  {
    value: 'Group Class Trainings',
    label: 'Group Class Trainings'
  },
  {
    value: 'Individual Class Trainings',
    label: 'Individual Class Trainings'
  },
  {
    value: 'Corperate Trainings',
    label: 'Corperate Trainings'
  }
];


const AvailableCoursesDashboard = (props) => {

 let clientId = "DfFCvW2ViuP8uyRPl1_yCobGqMXUF0m9qlX27WtWVMU";
 let query ="computing";
 let url = "https://api.unsplash.com/search/photos/?client_id=" +
           clientId +
           "&query=" +
            query

    const availableTrainingOfferList = props.availableTrainingOfferList;
    console.log("availableTrainingOfferList props.AVAILABLE: "+ JSON.stringify(availableTrainingOfferList));

    let classCode = "BATCH 204";
    let classCost = 20000;
    let testImg = 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6';

  return (

      <Container >

            <br />
            <br />

            <Formik

            enableReinitialize={true}
            initialValues={{}}
            validationSchema={Yup.object().shape({

                 })}

            onSubmit={(values, { setSubmitting }) => {

            setTimeout(() => {
            // alert('values: '+JSON.stringify(values, null, 2));
            //alert('classList: '+JSON.stringify(values.classList, null, 2));


            /*    let rollCallRequestRequest = {};
            rollCallRequestRequest.classCode = values.classCode;
            console.log("Before saving new Date(values.effectiveDate): "+new Date(values.effectiveDate));
            rollCallRequestRequest.effectiveDate =   new Date(values.effectiveDate);  // from examSetup - '2024-01-14'
            rollCallRequestRequest.isPresent =  values.isPresent;

            rollCallRequestRequest.rollCallJsonText = JSON.stringify(values, null, 2);
            alert('rollCallRequestRequest: '+JSON.stringify(rollCallRequestRequest, null, 2));

            console.log('rollCallRequestRequest before save: '+JSON.stringify(rollCallRequestRequest, null, 2));


            ClassService.saveRollCall(rollCallRequestRequest).then(
                 response => {
                  // courseList = response.data;
                  toast.success('RollCall saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                   console.log("RollCall saved successfully ...: "+ JSON.stringify(response));
                 },
             error => {
               toast.error('Error while saving RollCall', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
               console.log("Error saving RollCall [error.toString()] : "+ error.toString());
             }
            );*/

            setSubmitting(false);
            }, 400);
            }}

            render={({ values }) => (

            <Form>

            <FormitLogger />

            </Form>
            )}

            />

            <br />
            <br />


            <div>
                    <ImageList cols={4} gap={3}
                                          sx={{
                                            gridTemplateColumns:
                                              'repeat(auto-fill, minmax(250px, 1fr))!important',
                                          }}

                                          >

                            {
                            (null != availableTrainingOfferList ) &&
                                availableTrainingOfferList.map((item) => (


                                         <div>
                                                 <ImageListItem key={item.img}>
                                                       <img
                                                         srcSet={process.env.PUBLIC_URL + `/images/offers/${item.imagePath}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                         src={process.env.PUBLIC_URL + `/images/offers/${item.imagePath}?w=248&fit=crop&auto=format`}
                                                         alt={item.title}
                                                         loading="lazy"
                                                       />


 <img
         srcSet={`images/offers/${item.imagePath}`}
         src={`images/offers/${item.imagePath}`}
         alt={item.title}
         loading="lazy"
         width="248"
       />

<img
     srcSet={`/images/offers/${item.imagePath}`}
     src={`/images/offers/${item.imagePath}`}
     alt={item.title}
     loading="lazy"
     width="248"
    />






                                                       <ImageListItemBar
                                                         title={item.title}
                                                         subtitle={<span>by: {item.author}</span>}
                                                         position="below"
                                                       />
                                                 </ImageListItem>



                                                    <div>
                                                       <bold> {item.name} </bold>
                                                       <br />
                                                    </div>
                                                    <div>
                                                       {item.mktDescription}
                                                       <br />
                                                    </div>
                                                    <div>
                                                                <ul>
                                                                  Course Content:
                                                                  {
                                                                         ((item.courseListJson) && (item.courseListJson.length > 0)) &&
                                                                         JSON.parse(item.courseListJson).map(courseContent => (
                                                                                                     <li key={courseContent.code}> {courseContent.label} </li>
                                                                                                ))
                                                                  }
                                                                </ul>
                                                       <br />
                                                    </div>
                                                    <div>
                                                        Duration: {item.duration}
                                                       <br />
                                                    </div>
                                                    <div>
                                                        Price: {item.price}
                                                       <br />
                                                    </div>

                                                    <br />
                                                    <StudentEnrollment classCode={classCode} classCost={item.price} />


                                                    <br />
                                                    <div>
                                                        Code: {item.code}
                                                   </div>
                                                    <div>
                                                       Offer Type Code: {item.offerTypeCode}
                                                   </div>
                                                   <div>
                                                     Image Path: {process.env.PUBLIC_URL + `/images/offers/${item.imagePath} `}
                                                   </div>

                                        </div>

                               ))
                            }
                  </ImageList>
            </div>



    </Container>
  );

};

export default AvailableCoursesDashboard;