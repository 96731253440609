import React, { useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@material-ui/core/Paper";
import {ErrorOutline} from "@material-ui/icons";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";
import { Redirect} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import InputField from '../../../components/formfields/InputField';
import PasswordInputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import StudentService from "../../../services/StudentService";
import AuthService from "../../../services/auth.service";

import { login,authenticateUser } from "../../../actions/auth";

const SignInErrorMessagesSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

function validatePassword(value) {
  let error;
  if (value === '' || !value) {
      return error;
  }
}

 function SignIn(props) {

     const [user, setUser] = useState({});
     const [isLoggedIn, setIsLoggedIn] = useState(false);
     const dispatch = useDispatch();

     // <PasswordInputField name="password" label="Password" fullWidth  />

  return (

    <Container component="main" maxWidth="xs">
     <React.Fragment>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h5" variant="h5" align="center">Logon</Typography>

                    <Formik
                        initialValues={{
                        username: '',
                        password: ''
                        }}

                        validationSchema={Yup.object({
                                username: Yup.string()
                                .required("Required"),
                                password: Yup.string()
                                .min(5, "Must be 5 characters or more")
                                .required("Required")
                               })}

                        onSubmit={(values, { setSubmitting }) => {

                        setTimeout(() => {
                             setSubmitting(true);

                             //alert(JSON.stringify(values, null, 2));
                             let userRequest = {};
                             userRequest.username = values.username;
                             userRequest.password = values.password;
                             //alert('userRequest :'+JSON.stringify(userRequest, null, 2));

                            // 'dispatch' is returned by 'connect' - when your component is connectored to the store via 'connector' call in export
                           // const { dispatch, history } = props;

                          const { history } = props;

                            //  alert('props in submit in new SignIn :'+JSON.stringify(props, null, 2));

                           dispatch(authenticateUser(values.username, values.password))
                          // dispatch(login(values.username, values.password))  // works
                            .then(() => {
                              setIsLoggedIn(true)
                             // history.push("/profile");
                              history.push("/");
                              window.location.reload();
                            })
                            .catch(() => {

                            });
                                setSubmitting(false);
                              }, 400);
                            }
                        }

                    >

                        <Form >

                                <Box sx={{ mt: 1 }}>

                                  <InputField name="username" label="User Name"  autoFocus fullWidth />

                                  <PasswordInputField  type="password" name="password" label="Password" fullWidth  />

                                  <br />
                                  <br />
                                  <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me "
                                  />

                                  <Button type="submit" variant="contained"  size="medium" > Log on </Button>


                                  <Grid container>
                                        <Grid item xs>
                                          <Link href="/resetPasswordemail" variant="body2">
                                            Forgot password?
                                          </Link>
                                        </Grid>

                                        <Grid item>
                                          <Link href="/regStart" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                          </Link>
                                        </Grid>
                                  </Grid>

                                </Box>

                                {!isLoggedIn && (
                                  <>
                                    <Typography className="title">Session Timed Out</Typography>
                                    <Paper elevation={0} className="timeoutPaper">
                                      <ErrorOutline className="icon"/>
                                      <div className="message">
                                        <Typography>
                                            <span className="placeholderText">
                                              Your current session has timed out.
                                            </span>
                                          <div className="placeholderText">
                                            Please log in again to continue.
                                          </div>
                                        </Typography>
                                      </div>
                                    </Paper>
                                  </>
                                )}
                        </Form>

                 </Formik>

         </Box>
     </React.Fragment>
     <br />
  </Container>
  );
}




/*
function mapDispatchToProps = (dispatch) => {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;

  return {
    isLoggedIn: () => dispatch(isLoggedIn),
    message: () => dispatch(message),
    dispatch,
  }

}
*/


const mapDispatchToProps = (dispatch) => {

 // const { isLoggedIn } = this.props.auth;
 // const { message } = this.props.message;

const isLoggedIn = () => ({ type: 'LOGIN_SUCCESS' })
const message = () => ({ type: 'SET_MESSAGE' })

   return {
      isLoggedIn,
       message,
      dispatch,
    }
}

export default connect()(SignIn);


//export default connect(mapDispatchToProps)(SignIn)

//=================
//Once you have connected your component in this way, your component receives props.dispatch. You may use it to dispatch actions to the store.

//connect()(MyComponent)
// which is equivalent with
//connect(null, null)(MyComponent)

//export default connect()(SignIn)
//export default connect(mapStateToProps /** no second argument*/)(SignIn)

// or
//connect(mapStateToProps /** no second argument */)(MyComponent)