

import React, { useState, useEffect } from 'react';
//import BlogService from '../services/BlogService';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Card, Row, Col, Container } from "react-bootstrap";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormitLogger  from "../../common/FormitLogger";
import { Formik, Form,Field } from 'formik';
import * as Yup from 'yup';
import {
  StepLabel,
  Button, Typography, TextField,
  CircularProgress
} from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
import InputField from '../../components/formfields/InputField';
import SelectField from '../../components/formfields/SelectField';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import StudentService from '../../services/StudentService';
import FormDialog from "../../admin/components/FormDialog";
//import StudentSingleCourseEnrollment from "./StudentSingleCourseEnrollment";
import AvailableCoursesDashboard from './AvailableCoursesDashboard.component';
import MyEnrolledAndActiveCoursesDashboard from './MyEnrolledAndActiveCoursesDashboard.component';
import MyCompletedCourseDashboard from './MyCompletedCourseDashboard.component';

const courseGroups = [
  {
    code: 'JAVA',
    label: 'Java'
  },
  {
    code: '.NET',
    label: '.Net'
  },
  {
    code: 'DB',
    label: 'Database'
  }
];


const offerTypes = [
  {
    code: 'GROUPCLASS',
    label: 'Group Class Trainings'
  },
  {
    code: 'INDIVIDUAL',
    label: 'Individual Class Trainings'
  },
  {
    code: 'CORPORATE',
    label: 'Corporate Trainings'
  }
];

const pageSizes = [
  {
    code: '3',
    label: '3'
  },
  {
    code: '6',
    label: '6'
  },
  {
    code: '9',
    label: '9'
  }
];

const ClassesDashboard = (props) => {

 let clientId = "DfFCvW2ViuP8uyRPl1_yCobGqMXUF0m9qlX27WtWVMU";
 let query ="computing";
 let url = "https://api.unsplash.com/search/photos/?client_id=" +
           clientId +
           "&query=" +
            query


    const [trainingOfferMap, setTrainingOfferMap] = useState([]);
    const [currentOffer, setCurrentOffer] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [searchTitle, setSearchTitle] = useState("");

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);  // Total pages
    const [pageSize, setPageSize] = useState(3);

    useEffect(() => {
           getTrainingOfferMap();
       }, []);

    const getTrainingOfferMap = () => {

                  //let params = {};

                  //let params = getRequestParams(values.codeOrNameOrMktDescOrCourseListJsonSearchStr, values.courseListJson, values.code, page, pageSize)
                  let params = getRequestParams({}, {},{}, page, pageSize)
                  StudentService.getTrainingOffersMap(params).then( // by available, enrolled etc
                    response => {
                             //  console.log("Pageable TrainingOfferMap Fetched: "+ JSON.stringify(response.data));
                      console.log("Pageable TrainingOfferMap Fetched successfully");
                      setTrainingOfferMap(response.data.trainingOffersCollection);
                      setCount(response.data.totalPages);
                    },
                    error => {
                        console.log("error while fetching offer list ");
                     // console.log("error.toString(): "+ error.toString());
                    }
              );
  };



      // wild string,  tech stack, offer type
        const getRequestParams = (codeOrNameOrMktDescOrCourseListJsonSearchStr, courseListJson, code, page, pageSize) => {

            let data = {};

            if (codeOrNameOrMktDescOrCourseListJsonSearchStr) {
             // params["codeOrNameOrMktDescOrCourseListJsonSearchStr"] = codeOrNameOrMktDescOrCourseListJsonSearchStr;
              data.codeOrNameOrMktDescOrCourseListJsonSearchStr = codeOrNameOrMktDescOrCourseListJsonSearchStr;
            }

            if (courseListJson) {
              //params["courseListJson"] = courseListJson;
              data.courseListJson = courseListJson;
            }
            if (code) {
              //params["code"] = code;
              data.code = code;
            }

            if (page) {
              //params["page"] = page - 1;
              data.page = page - 1;
            }

            if (pageSize) {
             // params["size"] = pageSize;
              data.size = pageSize;
            }

            return data;
  };

  function getPageableTrainingOffersMap(values) {

          // alert("getPageableTrainingOffersMap(values): "+JSON.stringify(values));

           let params = getRequestParams(values.codeOrNameOrMktDescOrCourseListJsonSearchStr, values.courseListJson, values.code, page, pageSize)

           StudentService.getTrainingOffersMap(params).then( // by available, enrolled etc
             response => {
                        console.log("Pageable TrainingOfferMap Fetched: "+ JSON.stringify(response.data));
                        setTrainingOfferMap(response.data.trainingOffersCollection);
                        setCount(response.data.totalPages);
             },
             error => {
               console.log("error.toString(): "+ error.toString());
             }
       );
  };


 const handlePageChange = async (event, value, values) => {
      // alert(JSON.stringify("values: "+ values));
       setPage(value);
       // fetchTrainingOffers(values);
  }

 const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

 /*const fetchTrainingOffers = async (values) => {
        // TODO
        let params = {};
        let page = 3;
        let pageSize = 6;
        if(values.code &&  values.courseListJson!= 'Select Offer Type') {
               //  params["code"] = values.code;
         }
        if(values.courseListJson &&  values.courseListJson!= 'Select Tech Stack') {
               // params["courseListJson"] = values.courseListJson;
        }

        if(values.codeOrNameOrMktDescOrCourseListJsonSearchStr ) {
                params["codeOrNameOrMktDescOrCourseListJsonSearchStr"] = values.codeOrNameOrMktDescOrCourseListJsonSearchStr;
        }

        if (values.page) {
                params["page"] = page - 1;
        }

        if (values.pageSize) {
                params["size"] = pageSize;
        }

       StudentService.getTrainingOffersMap(params).then( // by available, enrolled etc
         response => {
                    console.log("Pageable TrainingOfferMap Fetched Here we go: "+ JSON.stringify(response.data));
           setTrainingOfferMap(response.data.trainingOffersCollection);
         },
         error => {
           console.log("error.toString(): "+ error.toString());
         }
   );

   }
*/

  return (

   <Container >

     <br />
     <br />

    <Formik

        enableReinitialize={true}
        initialValues={{
                  codeOrNameOrMktDescOrCourseListJsonSearchStr: '',
                  courseListJson: '',
                  code: ''
                }}

                validationSchema={Yup.object().shape({

                 })}

          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
                   //  alert('values: '+JSON.stringify(values, null, 2));
                   getPageableTrainingOffersMap(values);
                   setSubmitting(false);
            }, 400);
        }

        }

        render={({ values }) => (

        <Form>

        <FormitLogger />

         <Grid container spacing={3}>

                <Grid item xs={8} sm={4}>
                    <InputField
                      name="codeOrNameOrMktDescOrCourseListJsonSearchStr"
                      label="Search keyword"
                      fullWidth
                      />
                </Grid>
                <Grid item xs={5} sm={2}>
                        <SelectField
                             name="courseListJson"
                             label="Select Tech Stack"
                             data={courseGroups}
                             fullWidth
                       />
                  </Grid>

                    <Grid item xs={5} sm={2}>
                          <SelectField
                               name="code"
                               label="Select Offer Type"
                               data={offerTypes}
                               fullWidth
                         />
                    </Grid>

                  <Grid item xs={3} sm={2}>
                        <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit"  variant="outlined">
                            Go
                        </Button>
                  </Grid>
           </Grid>

           <Grid container spacing={3}>

                <Grid item xs={5} sm={2}>
                    <Pagination
                        className="my-3"
                        count={page}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        name="page"
                        onChange={handlePageChange}
                    />
                 </Grid>
                 <br />
                 <br />
                 <Grid item xs={5} sm={2}>
                          <SelectField
                           name="size"
                           label="Items per Page"
                           data={pageSizes}
                           fullWidth
                           onChange={handlePageSizeChange} value={pageSize}
                         />
                    </Grid>
           </Grid>


       </Form>
       )}

      />

     <br />
     <br />



                <div>

                 <hr />


                    {trainingOfferMap &&

                            <Tabs>
                                <TabList>
                                    <Tab>Available Course Offers</Tab>
                                    <Tab>My Enrolled & Active Courses</Tab>
                                    <Tab>My Completed Courses</Tab>
                                </TabList>


                                    <TabPanel>
                                         <AvailableCoursesDashboard availableTrainingOfferList={trainingOfferMap.AVAILABLE} />
                                    </TabPanel>
                                    <TabPanel>
                                        <MyEnrolledAndActiveCoursesDashboard enrolledTrainingOfferList={trainingOfferMap.INPROGRESS} />
                                        <MyEnrolledAndActiveCoursesDashboard enrolledTrainingOfferList={trainingOfferMap.APPLIED} />
                                        <MyEnrolledAndActiveCoursesDashboard enrolledTrainingOfferList={trainingOfferMap.ENROLLED} />
                                    </TabPanel>
                                    <TabPanel>
                                        <MyCompletedCourseDashboard completedTrainingOfferList={trainingOfferMap.COMPLETED} />
                                    </TabPanel>
                           </Tabs>
                     }

                </div>



    </Container>
  );

};

export default ClassesDashboard;