
import { connect } from "react-redux";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";

import { history } from '../helpers/history';
import EventBus from "../common/EventBus";  //
import AuthVerify from "../common/auth-verify";
import AuthTokenRefresh from "../common/auth-token-refresh";

//import ErrorBoundary from "./common/ErrorBoundary";
// For session inactivity component
import { AppStateProvider } from "../components/contexts/AppStateContext";
import Header from './Header';
// import Profile from "./components/Profile.component";
// import logo from '../images/xvlogo.png';
// const TopNaveMenu = () => {

import upriseLogo from '../static/images/uprise_logo.png';

class TopNaveMenu extends Component {

    constructor(props) {

        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
          showModeratorBoard: false,
          showAdminBoard: false,
          showUserBoard: false,
          currentUser: undefined,
          isAuthenticated: false
        };

        history.listen((location) => {
          props.dispatch(clearMessage()); // clear message when changing location
        });

      }

  componentDidMount() {
    const user = this.props.user;
    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        showUserBoard: user.roles.includes("ROLE_USER")
      });

      console.log('componentDidMount state in TopNavMenu :'+JSON.stringify(this.state, null, 2));
    }
 }


 /*
  EventBus.on("logout", () => {
            this.logOut();
    });


    JSON.parse(localStorage.getItem('user_byte_steroid')) || {},


  componentWillUnmount() {
        EventBus.remove("logout");
  }
*/



  logOut() {
    this.props.dispatch(logout());
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      showUserBoard: false,
      currentUser: undefined
    });
      // alert('Token is expired ..');
      // EventBus.dispatch("logout");

       history.push("/");
       window.location.reload();
  }

  render() {

    const { currentUser, showUserBoard, showModeratorBoard, showAdminBoard } = this.state;


    return (


      <div >
            <div  style={{margin: 0}} >


                        <ul className="app-menu">


                            <li>
                              <Link to={"/"} >
                                <img src={upriseLogo} alt="Xcelvations Logo" height="70" width="80" />
                              </Link>
                            </li>
                            <li>
                              <Link to={"/"}>
                                Home
                              </Link>
                            </li>
                            <li>
                              <Link to={"/classesDashboard"}>
                                IT Training
                              </Link>
                            </li>

                            <li>
                              <Link to={"/softwareDevDashboard"} >
                                Software Dev
                              </Link>
                            </li>



      {(currentUser && currentUser.roles && currentUser.roles.includes('ROLE_ADMIN')) ? (
                   <>
                            <li>
                                <Link to={"/blogHome"} >
                                  Blog
                                </Link>
                            </li>

                            <li>

                                <li style={{marginTop: 5}} className="dropbtn">
                                                Explore
                                                <i className="fa fa-caret-down"></i>
                                </li>

                               <ul style={{zIndex:10}}>
                                  <li><br style={{marginTop: 25}}></br></li>
                                  <li><Link to={"/publicationCreation"} >Write</Link></li>
                                  <li><Link to={"/blogHome"} >Blog</Link></li>
                                  <li><Link to={"/teachingResourceHome"} >Training Resources</Link></li>
                                  <li><Link to={"/publicResourceHome"} >Public Resources</Link></li>
                                  <li><Link to={"/personalResourceHome"} >Personal Resources</Link></li>
                                  <li><Link to={"/personalResourceNotesHome"} >Notes Resources</Link></li>
                                  <li><Link to={"/challenge"} >Challenge</Link></li>
                                  <li><Link to={"/download"} >Download</Link></li>
                                  <li><Link to={"/searches"} >Searches</Link></li>

                                 </ul>
                            </li>

            </>
            )
            :
            (
            <>
            </>
             )
            }


                              <li>
                                   <Link to={"/getInContactWIthUs"} >
                                       Contact Us
                                    </Link>
                              </li>

                              {(null != currentUser) ? (
                              <>
                              <li>
                                <Link to={"/profile"} >
                                   {currentUser.username} Dashboard
                                </Link>
                              </li>

                              <li>
                                 <Link to={"/"} onClick={this.logOut}>
                                     Log Out
                                  </Link>
                              </li>
                              </>
                              )
                               :
                               (
                                   <>
                                         <li>
                                             <Link to={"/signin"}>
                                               Logon
                                             </Link>
                                         </li>

                                         <li>
                                          <Link to={"/regStart"} >
                                            Register
                                          </Link>
                                         </li>

                                   </>
                                 )
                              }

                        </ul>






                        <Header />
            </div>

          <AuthVerify logOut={this.logOut}/>

          {

            /*<AuthTokenRefresh />*/

          }


      </div>

  );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}


export default connect(mapStateToProps)(TopNaveMenu);


//};
//export default TopNaveMenu;